import _ from 'lodash';
import { Language, OrganizationId } from '../../domain';
import { ExplorerId } from '../../domain/newExplorers';

export const allTeachSources = [
  'embed',
  'search',
  'default',
  'explorer',
  'plan',
  'channel-lesson',
  'searchembed',
  'onboarding-demo',
] as const;

export type TeachSource = (typeof allTeachSources)[number];

export function teacherPlanUrl(planId: string, { edit, planName }: { edit?: boolean; planName?: string } = {}): string {
  return `/app/${!edit ? 'view-' : ''}plan/${planId}${planName ? '/' + planName : ''}`;
}

export function teacherTeachUrl({
  lessonId,
  pinId,
  source,
  language,
  version,
}: {
  lessonId: string;
  pinId?: string;
  source?: TeachSource;
  language?: Language.Key;
  version?: 'v1' | 'v2';
}): string {
  const teachPart = version === 'v2' ? 'teach-v2' : 'teach';
  // Don't change order of the below array without changing teacher routes as well.

  const url =
    version === 'v2'
      ? _.compact(['/app', teachPart, source, lessonId, pinId]).join('/')
      : _.compact(['/app', source, teachPart, lessonId, pinId]).join('/');
  return language ? `${url}?lang=${language}` : url;
}

export function teacherAccountUrl(): string {
  return `/app/account/license`;
}

export function teacherMyLessonsUrl(): string {
  return `/app/explorer/lessons`;
}

// Use this alias whenever you need to redirect to a variable homebase.
// This is the myLessonsPage for now, but might change to a dashboard in the future.
export function teacherHome(): string {
  return teacherMyLessonsUrl();
}

export function teacherEmbedUrl(lessonId: string): string {
  return `/app/embed/${lessonId}`;
}

export function channelListRoute(): string {
  return '/app/channels/';
}

export function channelTeacherUrl(slug: string): string {
  return `/app/channel/${slug}`;
}

export function editorUrl(lessonId: string, pinId?: string): string {
  return `/app/lesson/${lessonId}${pinId ? `/${pinId}` : ''}`;
}

export function editorV2Url(lessonId: string, pinId?: string): string {
  return `/app/lesson-v2/${lessonId}${pinId ? `/${pinId}` : ''}`;
}

export function editPlanRoute(planId: string, planName?: string): string {
  return teacherPlanUrl(planId, { edit: true, planName });
}

export function mergeAccountRoute(): string {
  return `/app/account/merge`;
}

export function profileSettingsRoute(): string {
  return `/app/account/profile`;
}

export function schoolSettingsRoute(): string {
  return `/app/account/mySchools`;
}

export function privacyConditionsRoute(): string {
  return `/app/account/privacyConditions`;
}

export function exportAssignmentUrl(assignmentId: string, spreadsheetSettingsQuery: string, version: string): string {
  return `/app/assignments/${assignmentId}/export?choices=${spreadsheetSettingsQuery}&version=${version}`;
}

export function newExplorerRoute(folderId = '', appendix = ''): string {
  return `/app/explorer/lessons${folderId && '/' + folderId}${appendix && '/' + appendix}`;
}

export function newSharedExplorerRoute(explorerId: ExplorerId, appendix = ''): string {
  return `/app/explorer/shared/${explorerId}${appendix && '/' + appendix}`;
}

export function newTrashRoute(appendix: string) {
  return `/app/explorer/trash${appendix && '/' + appendix}`;
}

export function newSharedExplorerTrashRoute(explorerId: ExplorerId, appendix = ''): string {
  return `/app/explorer/trash/shared/${explorerId}${appendix && '/' + appendix}`;
}

export function groupsRoute(): string {
  return '/app/explorer/groups';
}

export function groupPage(
  groupId: string,
  page: 'lessons' | 'students' | 'progress' | 'lessons-archive' | undefined
): string {
  return `/app/explorer/groups/${groupId}` + (page ? `/${page}` : '');
}

export function qrCodeUrlToJoinLesson(studentUrl: string, pincode: string): string {
  const encodedStudentUrlWithPincode = encodeURIComponent(`${studentUrl}?code=${pincode}&qr=true`);

  return `/qrcode/${encodedStudentUrlWithPincode}?decode=true&background=fff&foreground=000`;
}

export function organizationAdminUrl(organizationId: string): string {
  return `/app/account/organization/${organizationId}`;
}

export function proposalRoute(organizationId: OrganizationId): string {
  return `/app/create-proposal?organization=${organizationId}`;
}

export function aiTools(language: Language.Key = 'en', slug?: string): string {
  return `/${language}/app/ai-tools${slug ? `/${slug}` : ''}`;
}
